const projectsData = [
    // {
    //   name: "Random Cat Generator",
    //   description: "Generates a random cat image using the Cat API.",
    //   languages: ["JavaScript", "APIs"],
    //   repoUrl: "https://github.com/hamaadasif/CatGenerator"
    // },
    // {
    //   name: "To Do List",
    //   description: "An efficient auto-sorting to-do list application that automatically organizes your tasks using React.",
    //   languages: ["JavaScript", "React"],
    //   repoUrl: "https://github.com/hamaadasif/ToDoList"
    // },
    {
      name: "GTA Real Estate Predictions",
      description: "Analyzes and predicts Toronto's real estate prices using Python, pandas, sklearn, and matplotlib for data processing and visualization.",
      languages: ["Python", "Data Analysis"],
      repoUrl: "https://github.com/hamaadasif/GTARealEstatePredicition"
    },
    // {
    //   name: "Google Calendar Event Creator",
    //   description: "Python GUI that uses the Google Calendar API to push events from the app to Google Calendar.",
    //   languages: ["Python", "APIs"],
    //   repoUrl: "https://github.com/hamaadasif/GoogleCalendarEventCreator"
    // },
    {
      name: "Virtual Escape Room",
      description: "Unity-based virtual escape room with two interactive rooms for a group capstone project.",
      languages: ["Unity", "C#"],
      repoUrl: ""
    }
];
  
export default projectsData; 

// {
//   name: "",
//   description: "",
//   languages: ["", ""],
//   repoUrl: ""
// }